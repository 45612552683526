import { h, render, App } from "vue";
import SmDialog from "@/components/common/modal/SmDialog.vue";
import { IDialogProps } from "@/types/components/modal";

type IDialogConponent = Promise<Promise<PromiseConstructor>>;

export default {
  install(app: App) {
    const dialog = async (props: IDialogProps): IDialogConponent => {
      let node: Node | null = null;

      const response: PromiseConstructor = await new Promise((resolve): void => {
        const container = document.createElement('div');

        const component = h(SmDialog, {
          ...props,
          ...{
            ...resolve,
            onClose: () => destroy()
          }
        });

        render(component, container);

        node = container.firstElementChild;
        if (node) document.body.appendChild(node);

        const destroy = (): void => {
          if (component) render(null, container);
        }
      });

      if (node) document.querySelector('body')?.removeChild(node);

      return response;
    }

    app.provide('dialog', dialog);
  }
}