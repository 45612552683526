import { computed, type ComputedRef } from 'vue';
import { IButtonProps as IButtonPropsOriginal, ICommonEmits } from "@/types/components/buttons";

interface IButtonProps {
  ariaLabel?: IButtonPropsOriginal['ariaLabel'];
}

interface IUseButtonReturn {
  computedAriaLabel: ComputedRef<string>; 
  handleClick: () => void;
  handleKeyDownEnter: () => void;
  handleKeyUpSpace: () => void;
}

export function useButton({ ariaLabel }: IButtonProps, emits?: ICommonEmits) {
  const computedAriaLabel = computed((): string => ariaLabel || '');

  const handleClick = (): void => {
    if (!emits) return;

    emits('click');
  };
  const handleKeyDownEnter = (): void => {
    if (!emits) return;

    emits('keydown.enter');
  };
  const handleKeyUpSpace = (): void => {
    if (!emits) return;

    emits('keyup.space');
  };

  return <IUseButtonReturn>{
    computedAriaLabel,
    handleClick,
    handleKeyDownEnter,
    handleKeyUpSpace
  }
}