<template>
  <teleport to="body">
    <div
      class="sm-dialog"
      @click="handleCancelByBackdrop"
    >
      <div class="sm-dialog__alert">
        <header
          v-if="showHeader"
          class="sm-dialog__header"
        >
          <p
            v-if="title"
            class="sm-dialog__title"
            v-html="title"
          ></p>
          <sm-icon
            v-if="showCloseIcon"
            name="Cancel"
            :size="24"
            class="sm-dialog__close"
            @click="handleCancel"
          />
        </header>

        <p class="sm-dialog__body">
          {{ text }}
        </p>

        <footer
          v-if="showFooter"
          class="sm-dialog__footer"
        >
          <sm-button
            v-if="showCloseButton"
            type="neutral"
            @click="handleCancel"
          >
            {{ closeButtonText }}
          </sm-button>
          <sm-button
            v-if="showConfirmButton"
            @click="handleApply"
          >
            {{ confirmButtonText }}
          </sm-button>
        </footer>
      </div>
    </div>
  </teleport>
</template>

<script
  lang="ts"
  setup
>
// Modules
import { computed } from 'vue';

// Components
import SmIcon from '@/components/common/SmIcon/SmIcon.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';

// Types
import { IDialogProps } from '@/types/components/modal';

// Props

const {
  title,
  showCloseIcon,
  text,
  showCloseButton,
  showConfirmButton,
  closeButtonText,
  confirmButtonText,
  cancebleBackdrop,
} = withDefaults(
  defineProps<IDialogProps>(),
  {
    title: '',
    showCloseIcon: false,
    text: '',
    showCloseButton: true,
    showConfirmButton: true,
    closeButtonText: 'Отмена',
    confirmButtonText: 'Ок',
    cancebleBackdrop: false,
  }
);

// Emits

const emits = defineEmits<{
  (e: 'cancel' | 'confirm' | 'close'): void;
}>();

// Computed

const showHeader = computed((): boolean => (
  !!title || showCloseIcon
));

const showFooter = computed((): boolean => (
  showCloseButton || showConfirmButton
));

// Methods

const handleCancel = (): void => {
  emits('cancel');
  emits('close');
}
const handleApply = (): void => {
  emits('confirm');
  emits('close');
}
const handleCancelByBackdrop = (): void => {
  if (!cancebleBackdrop) return;

  emits('cancel');
  emits('close');
}

</script>

<style lang="scss">
.sm-dialog {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  overflow-y: auto;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  background: linear-gradient(0deg, rgba(69, 88, 108, 0.33), rgba(69, 88, 108, 0.33));
}

.sm-dialog--closable-backdrop {
  cursor: pointer;
}

.sm-dialog__alert {
  width: 500px;
  height: auto;
  max-height: 600px;

  display: flex;
  flex-direction: column;
  gap: var(--gap);

  background-color: var(--Background);
  border-radius: var(--border-radius);

  box-shadow: 0px 4px 8px 0px #3C507714;
  box-shadow: 0px 8px 16px 0px #152D5B1C;
}

.sm-dialog__header {
  display: flex;
  justify-content: space-between;
  gap: var(--gap-s);

  padding: var(--padding) var(--padding) 0;
}

.sm-dialog__header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.sm-dialog__title {
  flex-basis: 100%;
  flex-grow: 1;

  @include h3;
  color: var(--Title);

  margin: 0;
}

.sm-dialog__close {
  flex-shrink: 0;

  color: var(--Icon);

  cursor: pointer;

  @include transition(('color'));

  &:hover,
  &:active {
    color: var(--IconHoverPress);
  }
}

.sm-dialog__body {
  flex-grow: 1;
  padding-inline: var(--padding);
  overflow: auto;
}

.sm-dialog__footer {
  display: flex;
  justify-content: flex-end;
  gap: var(--gap-s);

  padding: 0 var(--padding) var(--padding);
}

.sm-dialog__footer-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--gap-s);
}

// For root
.sm-dialog__animation-enter-from,
.sm-dialog__animation-leave-to {
  transition: opacity .15s;
  opacity: 0;
}

.sm-dialog__animation-enter-active,
.sm-dialog__animation-leader-active {
  transition: opacity .15s;
}

// For window
.sm-dialog__animation-window-enter-from {
  transition: opacity .15s, transform .15s;
  opacity: 0;
  transform: translateY(-200%);
}

.sm-dialog__animation-window-leave-active {
  transition: transform .15s;
  transform: translateY(200%);
}

.sm-dialog__animation-window-enter-active,
.sm-dialog__animation-window-leader-active {
  transition: opacity .15s, transform .15s;
}
</style>