<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :aria-labelledby="props.name"
    :width="width"
    :height="height"
    :viewBox="viewbox"
    preserveAspectRatio="xMinYMin meet"
    role="presentation"
    class="sm-icon"
  >
    <g
      :fill="props.color"
      ref="iconWrap"
    >
      <component
        :is="iconComponent"
        ref="icon"
      />
    </g>
  </svg>
</template>

<script
  setup
  lang="ts"
>
import { IIconProps, ISvgBox } from '@/types/components/icons'

import {
  defineAsyncComponent,
  ref,
  computed,
  watch,
} from 'vue';
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter';

// [SART] Props
const props = withDefaults(
  defineProps<IIconProps>(),
  {
    size: 16,
    color: 'currentColor',
    viewBox: undefined,
    name: undefined
  }
);
// [END] Props

// [START] Variables
let icon = ref<HTMLElement | null>(null);

const setIcon = (): Promise<typeof import('*.vue')> => {
  return defineAsyncComponent(async () => {
    return await import(
      `@/components/common/SmIcon/icons/${props.size}/${capitalizeFirstLetter(props.name)}.vue`
    );
  });
};

let iconComponent = setIcon();
// [END] Variables

// [START] Computed
const width = computed((): string => `${props.size}px`);
const height = computed((): string => `${props.size}px`);

const svgBoxSize = computed<ISvgBox>((): ISvgBox => {
  let data: ISvgBox = {
    x: 0,
    y: 0,
    width: 16,
    height: 16
  };

  if (icon.value instanceof SVGGraphicsElement) {
    const svg = icon.value;
    data = svg.getBBox();
  }

  return data;
});

const viewbox = computed((): string => {
  if (props.viewbox) return props.viewbox;

  const size = +props.size;
  const { width, height } = svgBoxSize.value;
  // const computedWidth = width < size ? size : width;
  // const computedHeight = height < size ? size : height;
  const computedWidth = Math.max(width, size);
  const computedHeight = Math.max(height, size);
  return `0 0 ${computedWidth} ${computedHeight}`;
});
// [END] Computed

watch(
  () => props.name,
  (val: IIconProps, oldVal: IIconProps): void => {
    if (val !== oldVal && icon.value) {
      iconComponent = setIcon();
    }
  },
);
</script>
@/types/components/icons