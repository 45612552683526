import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import '@/assets/style/main.scss';

import { ClickOutsideDirectivePlugin } from '@/directives/vClickOutside';
import SmDialog from '@/plugins/SmDialog';

createApp(App)
  .use(router)
  .use(ClickOutsideDirectivePlugin)
  .use(SmDialog)
  .mount('#app')
