import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/ui-kit',
    name: 'UIKit',
    component: () => import('../views/uiKit/UIKitView.vue'),
    meta: {
      title: 'UI Kit'
    },
    children: [
      {
        path: 'colors',
        name: 'UIKit.Colors',
        component: () => import('../views/uiKit/ColorsView.vue'),
        meta: {
          title: 'Цвета'
        },
      },
      {
        path: 'typography',
        name: 'UIKit.Typography',
        component: () => import('../views/uiKit/TypographyView.vue'),
        meta: {
          title: 'Типографика'
        },
      },
      {
        path: 'icons',
        name: 'UIKit.Icons',
        component: () => import('../views/uiKit/IconsView.vue'),
        meta: {
          title: 'Иконки'
        },
      },
      {
        path: 'buttons',
        name: 'UIKit.Buttons',
        component: () => import('../views/uiKit/ButtonsView.vue'),
      },
      {
        path: 'float',
        name: 'UIKit.Float',
        component: () => import('../views/uiKit/FloatView.vue'),
        meta: {
          title: 'Высплывающие элементы'
        },
      },
      {
        path: 'misc',
        name: 'UIKit.Misc',
        component: () => import('../views/uiKit/MiscView.vue'),
        meta: {
          title: 'Прочее'
        },
      },
      {
        path: 'inputs',
        name: 'UIKit.Inputs',
        component: () => import('../views/uiKit/InputsView.vue'),
        meta: {
          title: 'Поля ввода'
        }
      },
    ]
  },
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
