<template>
  <sm-app-preloader v-model="appIsLoading"></sm-app-preloader>

  <router-view />
</template>

<script
  lang="ts"
  setup
>
// Modules
import { ref } from 'vue';
import { forEach } from 'lodash';

// Composables
import { useTheme } from '@/composables/useTheme';

// Components
import SmAppPreloader from '@/components/common/loaders/SmAppPreloader.vue';

// Types
import { AppPreloaderModelValue } from '@/types/components/loaders';

// Composables
const { colors } = useTheme();

// Data

const appIsLoading = ref<AppPreloaderModelValue>(false);

// Methods

const init = () => {
  const root = document.documentElement;

  appIsLoading.value = true;

  forEach(colors.value, (value, key): void => {
    root.style.setProperty(`--${key}`, value);
  });

  // FIXME: Demo-вариант снятия прелоадера
  setTimeout((): void => {
    appIsLoading.value = false;
  }, 2000);
}

// Hooks

init();
</script>@/types/components/loaders