<template>
  <transition name="app-preloader">
    <div
      v-if="modelValue"
      class="sm-app-preloader"
    >
      <sm-loader
        :size="72"
        class="sm-app-preloader__loader"
      ></sm-loader>
    </div>
  </transition>
</template>

<script
  lang="ts"
  setup
>
// Components
import SmLoader from '@/components/common/loaders/SmLoader.vue';

// Types
import { AppPreloaderModelValue } from '@/types/components/loaders';

// Models

const modelValue = defineModel<AppPreloaderModelValue>();
</script>

<style
  lang="scss"
  scoped
>
.sm-app-preloader {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 1000;

  background-color: var(--Background);
}

.sm-app-preloader__loader {
  color: var(--Primary);
}

// Transition
.app-preloader-enter-active,
.app-preloader-leave-active {
  transition: opacity .3s ease;
}

.app-preloader-enter-from,
.app-preloader-leave-to {
  opacity: 0;
}
</style>@/types/components/loaders